import { subscribe } from "@goalteller/app-kit/api/rest/endpoints/public";
import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import BasicTextInput from "@goalteller/app-kit/components/forms/BasicTextInput";
import { MATCHERS } from "@goalteller/app-kit/data/regexs";
import useBreakpoints from "@goalteller/app-kit/hooks/useBreakpoints";
import analytics from "@goalteller/app-kit/tools/analytics";
import catchFormError from "@goalteller/app-kit/tools/catchFormError";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import dynamic from "next/dynamic";
import { useRef, useState } from "react";

import girlWithImage2 from "../images/girl-with-phone-2.webp";
import AmpImage from "./AmpImage";
import ThemeWrapper from "./ThemeWrapper";
import styles from "./newsletterDark.module.css";

const DynamicNewsletterModal = dynamic(() => import("./NewsletterModal"));

export default function Newsletter({
  darkTopHalf = false,
  theme = "dark",
}: {
  darkTopHalf?: boolean;
  theme?: "dark" | "light";
}) {
  const [inputEmail, setInputEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const email = useRef(null);
  const [success, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isMobile, isTablet } = useBreakpoints();

  const handleSubmit = async () => {
    analytics.setUserInfo({ email: inputEmail });
    analytics.setInfo({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Lead_Source: "Website - Newsletter",
    });
    setLoading(true);
    try {
      if (!inputEmail) {
        throw new Error("Please enter email");
      }
      if (!MATCHERS.email.test(inputEmail)) {
        throw new Error("This is not a valid email");
      }
      setEmailError("");
      await subscribe({ data: { email: inputEmail } });

      analytics.setInfo({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Lead_Source: "Website - Newsletter",
      });
      setShowSuccess(true);
      setInputEmail("");
    } catch (error) {
      const catchedError = catchFormError(error);
      setEmailError(catchedError.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeWrapper className={styles.root} id="newsletter" theme={theme}>
      <div className={darkTopHalf ? styles.background : ""} />
      <MaxWidthContainer className={styles.wrapper} isSection>
        <div className={styles.container}>
          <div className={styles.ribbonWrapper}>
            <h6 className={styles.ribbon}>Join a community of 50k Readers !</h6>
          </div>
          <div className={styles.leftContainer}>
            <SectionTitle as="h2" className={styles.heading} variant="gradient">
              Cut through the clutter. <br />
              Get what you need.
            </SectionTitle>
            <p className={styles.desc}>
              Get financial summaries, actionable insights and relevant
              knowledge in ONE weekly newsletter. We’re fluff-free and
              fact-full!
            </p>
            <div className={styles.emailInputContainer}>
              <div className={styles.inputContainer}>
                <BasicTextInput
                  className={styles.emailInput}
                  innerClassNames={{ input: styles.input, label: styles.label }}
                  label="Enter your email address"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInputEmail(event.target.value);
                  }}
                  placeholder="Enter email address"
                  ref={email}
                />
                <Button
                  className={styles.joinButton}
                  isBusy={loading}
                  onClick={() => {
                    void handleSubmit();
                  }}
                  variant="primary"
                >
                  Join Now
                </Button>
              </div>
              {!!emailError && (
                <div className={styles.errorMsg}>{emailError}</div>
              )}
              <DynamicNewsletterModal
                setShowSuccess={setShowSuccess}
                success={success}
              />
            </div>
          </div>
          {!isMobile && !isTablet && (
            <div className={styles.rightContainer}>
              <div className={styles.imageBg}>
                <AmpImage
                  alt="A girl sending a email to goalteller"
                  height={570}
                  loading="eager"
                  src={girlWithImage2}
                />
              </div>
            </div>
          )}
        </div>
      </MaxWidthContainer>
    </ThemeWrapper>
  );
}
