import Rajendra from "../images/testimonials/Rajendra.webp";
import Amit from "../images/testimonials/amit-jain.webp";
import Gaurav from "../images/testimonials/gaurav.webp";
import Nitin from "../images/testimonials/nitin.webp";
import Raghu from "../images/testimonials/raghu.webp";
import Sharat from "../images/testimonials/sharat.webp";
import Yogesh from "../images/testimonials/yogesh.webp";

export const TESTIMONIALS = [
  {
    content:
      "When the conflict is lesser, then the business is interested in whats right for the customer and that’s when we spotted GoalTeller. We really liked the idea of setting goals, which is very important and helping people use different products to reach it",
    designation: "CEO, Zerodha",
    headline:
      "A lot of wealth creation has happened in the US because its advisory led",
    img: Nitin,
    link: "https://www.youtube.com/embed/pMGoRpKzHp0",
    name: "Nitin Kamath",
  },
  {
    content:
      "Their advisors have personally helped me, I have benefitted and GT has also done similar kind of work with a whole lot of folks!",
    designation: "Founder, Zolve",
    headline: "I would strongly recommend Goalteller",
    img: Raghu,
    link: "https://www.youtube.com/embed/PJUtxJ0u1bY",
    name: "Raghunandan G",
  },
  {
    content:
      "GoalTeller's SEBI-registered advisors offer in-depth, reliable guidance that's been instrumental in helping me navigate and achieve my financial goals. Their expertise and legitimacy add real value to the planning process.",
    headline:
      "GoalTeller's SEBI registration adds authenticity to their expert, thorough financial guidance.",
    img: Gaurav,
    link: "https://www.youtube.com/embed/YaXA_XfrVQg",
    name: "CA Gaurav Jain",
  },
  {
    content:
      "GoalTeller has redefined financial planning for me. As a CFO experienced with various advisors, I find their platform comprehensive, user-friendly, and backed by a knowledgeable team—all at a fair price. It’s a standout service for streamlining financial decisions.",
    designation: "VP, Toyota India",
    headline: "How GoalTeller helped me take charge of my financial journey?",
    img: Amit,
    link: "https://www.youtube.com/embed/naHmxi1BnHw",
    name: "Amit Jain",
  },
  {
    content:
      "GoalTeller puts investors first, just like how we prioritize our patients. As someone who's always wary of financial advisors, I can confidently say that GoalTeller's commitment to transparency and integrity sets them apart from the rest. I'm grateful to have found a platform that prioritizes my interests and helps me achieve my financial goals",
    headline:
      "Experience personalized and trustworthy financial advice with GoalTeller's investor-first approach.",
    img: Yogesh,
    link: "https://www.youtube.com/embed/BCIUsM69U2I",
    name: "Dr. Yogesh K Pithwa",
  },
  {
    content:
      "With GoalTeller, we've streamlined our investments, reducing costs and maximizing returns. Plus, we can easily stay on top of our portfolio anytime, anywhere. It's the ultimate tool for financial empowerment.",
    designation: "Ex Promoter, MD-Gokaldas Warehousing",

    headline: "GoalTeller: The solution to our investment management needs.",
    img: Rajendra,
    name: "Rajendra J Hinduja",
  },
  {
    content:
      "As an entrepreneur and CFO, finding accessible, comprehensive financial advice has been a challenge—until I discovered GoalTeller. Their expertise and resources have empowered me to manage my wealth effectively. With a team that guides me through investing and retirement planning, I highly recommend them!",
    designation: "Founder - ZenComply",
    headline:
      "Take Control of Your Finances and Achieve Your Goals with Goalteller's Expert Financial Planning.",
    img: Sharat,
    name: "Sharat Khurana",
  },
];
