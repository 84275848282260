import HeadWrapper from "@goalteller/app-kit/components/head/NxHeadWrapper";

import AboutUs from "../components/HomePage/AboutUs";
import _AnnualReportDark from "../components/HomePage/AnnualReportDark";
import LandingIntroDark from "../components/HomePage/LandingIntroDark";
import _LandingInvestorDark from "../components/HomePage/LandingInvestorDark";
import _UltimateDark from "../components/HomePage/UltimateScroll/UltimateDark";
import _WhyChooseUsDark from "../components/HomePage/WhyChooseUsDark";
import NewsletterDark from "../components/NewsletterDark";
import _PricingBanner from "../components/PricingBanner";
import _Reviews from "../components/Reviews";
import { lazyTillView } from "../wrappers/LazyTillView";

const UltimateDark = lazyTillView(_UltimateDark);
const WhyChooseUsDark = lazyTillView(_WhyChooseUsDark);
const Reviews = lazyTillView(_Reviews);
const PricingBanner = lazyTillView(_PricingBanner);
const AnnualReportDark = lazyTillView(_AnnualReportDark);
const LandingInvestorDark = lazyTillView(_LandingInvestorDark);

export default function HomePage() {
  return (
    <>
      <HeadWrapper
        description="GoalTeller is one-stop shop for systematic investment calculator. We provide personalized financial advice, investment management, and tools to help you achieve your goals."
        keywords="systematic investment calculator 500k monthly searches"
        title="Comprehensive Investment Planning & Systematic Investment Calculator"
      />
      <LandingIntroDark />
      <AboutUs />
      <UltimateDark />
      <WhyChooseUsDark />
      <Reviews />
      <PricingBanner />
      <AnnualReportDark />
      <LandingInvestorDark />
      <NewsletterDark darkTopHalf />
    </>
  );
}
