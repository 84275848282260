import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import router from "next/router";

import aboutImage from "../../images/landing/about-section-image.webp";
import AmpImage from "../AmpImage";
import ThemeWrapper from "../ThemeWrapper";
import styles from "./aboutUs.module.css";

export default function AboutUs({
  theme = "dark",
}: {
  theme?: "dark" | "light";
}) {
  return (
    <ThemeWrapper theme={theme}>
      <MaxWidthContainer className={styles.mainContainer}>
        <div className={styles.wrapper}>
          <div className={styles.imageWrapper}>
            <AmpImage
              alt="about image"
              className={styles.image}
              src={aboutImage}
            />
          </div>

          <div className={styles.headerContainer}>
            <SectionTitle as="h2" className={styles.header}>
              About
              <SectionTitle
                as="span"
                className={styles.header}
                variant="gradient"
              >
                {" "}
                GoalTeller
              </SectionTitle>
            </SectionTitle>
            <h4 className={styles.infoText}>
              GoalTeller is a SEBI-registered financial planning app that helps
              you manage, grow, and visualize your wealth. Get personalized
              investment strategies, goal tracking, and expert advice on Mutual
              Funds, Bonds, PMS, and more—all in one platform.
            </h4>

            <Button
              className={styles.btnLearnMore}
              onClick={() => void router.push("/about")}
              variant="primary"
            >
              Learn More
            </Button>
          </div>
        </div>
      </MaxWidthContainer>
    </ThemeWrapper>
  );
}
